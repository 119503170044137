export const RightArrow = ({
  color = "white",
  size = "24rem",
}: {
  color?: string;
  size?: string;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g mask="url(#mask0_23_1724)">
        <path
          d="M17.1792 12.75H5.80225C5.58943 12.75 5.41123 12.6782 5.26765 12.5347C5.12405 12.3911 5.05225 12.2129 5.05225 12.0001C5.05225 11.7872 5.12405 11.609 5.26765 11.4655C5.41123 11.3219 5.58943 11.2501 5.80225 11.2501H17.1792L12.0099 6.08083C11.8612 5.93211 11.7878 5.75808 11.7897 5.55873C11.7917 5.35936 11.8702 5.18212 12.0253 5.027C12.1804 4.88214 12.3561 4.80714 12.5522 4.802C12.7484 4.79687 12.924 4.87187 13.0791 5.027L19.4195 11.3674C19.5131 11.461 19.5791 11.5597 19.6176 11.6635C19.656 11.7674 19.6753 11.8795 19.6753 12.0001C19.6753 12.1206 19.656 12.2327 19.6176 12.3366C19.5791 12.4404 19.5131 12.5391 19.4195 12.6327L13.0791 18.9731C12.9407 19.1116 12.7692 19.1824 12.5647 19.1856C12.3602 19.1888 12.1804 19.118 12.0253 18.9731C11.8702 18.818 11.7926 18.6398 11.7926 18.4385C11.7926 18.2372 11.8702 18.059 12.0253 17.9039L17.1792 12.75Z"
          fill={color}
        />
        <path
          d="M17.1792 12.75H5.80225C5.58943 12.75 5.41123 12.6782 5.26765 12.5347C5.12405 12.3911 5.05225 12.2129 5.05225 12.0001C5.05225 11.7872 5.12405 11.609 5.26765 11.4655C5.41123 11.3219 5.58943 11.2501 5.80225 11.2501H17.1792L12.0099 6.08083C11.8612 5.93211 11.7878 5.75808 11.7897 5.55873C11.7917 5.35936 11.8702 5.18212 12.0253 5.027C12.1804 4.88214 12.3561 4.80714 12.5522 4.802C12.7484 4.79687 12.924 4.87187 13.0791 5.027L19.4195 11.3674C19.5131 11.461 19.5791 11.5597 19.6176 11.6635C19.656 11.7674 19.6753 11.8795 19.6753 12.0001C19.6753 12.1206 19.656 12.2327 19.6176 12.3366C19.5791 12.4404 19.5131 12.5391 19.4195 12.6327L13.0791 18.9731C12.9407 19.1116 12.7692 19.1824 12.5647 19.1856C12.3602 19.1888 12.1804 19.118 12.0253 18.9731C11.8702 18.818 11.7926 18.6398 11.7926 18.4385C11.7926 18.2372 11.8702 18.059 12.0253 17.9039L17.1792 12.75Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
